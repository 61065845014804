import { Disclosure, Transition } from '@headlessui/react'

import {
  BriefcaseIcon,
  CreditCardIcon,
  MinusSmallIcon,
  PlusSmallIcon,
  SparklesIcon,
} from '@heroicons/react/24/outline'

import { useTranslation } from 'next-i18next'

import { Container } from '@/components/Container'

const FAQS = [
  {
    icon: SparklesIcon,
    q: 'landing.faq.items.row1.q',
    a: 'landing.faq.items.row1.a',
  },
  {
    icon: SparklesIcon,
    q: 'landing.faq.items.row2.q',
    a: 'landing.faq.items.row2.a',
  },
  {
    icon: SparklesIcon,
    q: 'landing.faq.items.row7.q',
    a: 'landing.faq.items.row7.a',
  },
  {
    icon: BriefcaseIcon,
    q: 'landing.faq.items.row3.q',
    a: 'landing.faq.items.row3.a',
  },
  {
    icon: BriefcaseIcon,
    q: 'landing.faq.items.row0.q',
    a: 'landing.faq.items.row0.a',
  },
  {
    icon: BriefcaseIcon,
    q: 'landing.faq.items.row6.q',
    a: 'landing.faq.items.row6.a',
  },
  {
    icon: CreditCardIcon,
    q: 'landing.faq.items.row4.q',
    a: 'landing.faq.items.row4.a',
  },
  {
    icon: CreditCardIcon,
    q: 'landing.faq.items.row5.q',
    a: 'landing.faq.items.row5.a',
  },
  {
    icon: CreditCardIcon,
    q: 'landing.faq.items.row8.q',
    a: 'landing.faq.items.row8.a',
  },
  {
    icon: CreditCardIcon,
    q: 'landing.faq.items.row9.q',
    a: 'landing.faq.items.row9.a',
  },
]

export function Faqs() {
  const { t } = useTranslation('landing')

  return (
    <section
      id="faqs"
      aria-labelledby="faqs-title"
      className="m-auto max-w-4xl border-t border-violet-200 py-20 sm:py-32"
    >
      <Container>
        <div className="mx-auto max-w-2xl">
          <h2
            id="faqs-title"
            className="text-center text-4xl font-semibold tracking-tight"
          >
            {t('landing.faq.title')}
          </h2>
        </div>
        <div className="mx-auto divide-y divide-violet-950/10">
          <dl className="mt-10 space-y-6 divide-y divide-violet-950/10">
            {FAQS.map((item) => (
              <Disclosure as="div" key={item.q} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left">
                        <span className="inline-flex items-center gap-2 text-base font-semibold leading-7">
                          {item.icon && <item.icon className="h-5 w-5" />}
                          {t(item.q)}
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusSmallIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          ) : (
                            <PlusSmallIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Transition
                      enter="transition duration-200 ease-out"
                      enterFrom="transform opacity-0"
                      enterTo="transform opacity-200"
                      leave="transition duration-100 ease-out"
                      leaveFrom="transform opacity-100"
                      leaveTo="transform opacity-0"
                    >
                      <Disclosure.Panel as="dd" className="mt-2 pr-12">
                        <p className="text-base leading-7 text-violet-950/80">
                          {t(item.a)}
                        </p>
                      </Disclosure.Panel>
                    </Transition>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </Container>
    </section>
  )
}
