import {
  ScaleIcon,
  CreditCardIcon,
  HeartIcon,
  ChatBubbleBottomCenterTextIcon,
  LockClosedIcon,
  DevicePhoneMobileIcon,
  ArrowTopRightOnSquareIcon,
} from '@heroicons/react/24/outline'

import { useTranslation } from 'next-i18next'
import Link from 'next/link'

import { Container } from '@/components/Container'
import { IDS } from '@/constants/ids'
import { SECTIONS_LINKS } from '@/constants/links'

export function SecondaryFeatures() {
  const { t } = useTranslation('landing')

  const features = [
    {
      name: t('landing.secondaryFeatures.feature1.name'),
      description: t('landing.secondaryFeatures.feature1.description'),
      icon: ScaleIcon,
      cta: {
        text: t('landing.secondaryFeatures.feature1.linkText'),
        href: SECTIONS_LINKS.PRICING_COMFORT,
      },
    },
    {
      name: t('landing.secondaryFeatures.feature2.name'),
      description: t('landing.secondaryFeatures.feature2.description'),
      icon: CreditCardIcon,
      cta: {
        text: t('landing.secondaryFeatures.feature2.linkText'),
        href: SECTIONS_LINKS.PRICING,
      },
    },
    {
      name: t('landing.secondaryFeatures.feature3.name'),
      description: t('landing.secondaryFeatures.feature3.description'),
      icon: HeartIcon,
      cta: {
        text: t('landing.secondaryFeatures.feature3.linkText'),
        href: SECTIONS_LINKS.PRICING,
      },
    },
    {
      name: t('landing.secondaryFeatures.feature4.name'),
      description: t('landing.secondaryFeatures.feature4.description'),
      icon: ChatBubbleBottomCenterTextIcon,
      cta: {
        text: t('landing.secondaryFeatures.feature4.linkText'),
        href: SECTIONS_LINKS.CONTACT_US,
      },
    },
    {
      name: t('landing.secondaryFeatures.feature5.name'),
      description: t('landing.secondaryFeatures.feature5.description'),
      icon: LockClosedIcon,
    },
    {
      name: t('landing.secondaryFeatures.feature6.name'),
      description: t('landing.secondaryFeatures.feature6.description'),
      icon: DevicePhoneMobileIcon,
    },
  ]

  return (
    <section id={IDS.SECONDARY_FEATURES} className="py-20 sm:py-32">
      <Container>
        <div className="mx-auto max-w-2xl sm:text-center">
          <h2 className="text-3xl font-bold tracking-tight">
            {t('landing.secondaryFeatures.title')}
          </h2>
          <p className="mt-2 text-lg text-violet-950/80">
            {t('landing.secondaryFeatures.description')}
          </p>
        </div>
        <ul
          role="list"
          className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-6 text-sm sm:mt-20 sm:grid-cols-2 md:gap-y-10 lg:max-w-none lg:grid-cols-3"
        >
          {features.map((feature) => (
            <li
              key={feature.name}
              className="group rounded-xl border border-violet-950/10 p-8 shadow-sm shadow-violet-100  transition-all hover:bg-violet-50/50 hover:shadow-lg hover:shadow-violet-100 "
            >
              <div className="flex items-center justify-between font-medium uppercase tracking-widest">
                <div className="w-fit rounded-full bg-violet-100 p-2 text-violet-900">
                  <feature.icon className="h-5 w-5" />
                </div>
                {feature.cta && (
                  <Link
                    href={feature.cta.href}
                    className="flex items-center gap-2 rounded-lg"
                  >
                    <span className="transition-all group-hover:opacity-100 group-hover:transition-all md:opacity-0">
                      {feature.cta.text}
                    </span>{' '}
                    <ArrowTopRightOnSquareIcon className="h-4 w-4" />
                  </Link>
                )}
              </div>
              <h3 className="mt-6 text-xl font-semibold tracking-tight">
                {feature.name}
              </h3>
              <p className="mt-2 text-violet-950/80">{feature.description}</p>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  )
}
