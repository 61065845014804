import { useTranslation } from 'next-i18next'

import { CircleBackground } from '@/components/CircleBackground'
import { Container } from '@/components/Container'
import { ENV } from '@/constants/env'

export function CallToAction() {
  const { t } = useTranslation('landing')
  return (
    <section
      id="get-free-shares-today"
      className="relative overflow-hidden bg-violet-700 py-20 sm:py-28"
    >
      <div className="absolute left-20 top-1/2 -translate-y-1/2 sm:left-1/2 sm:-translate-x-1/2">
        <CircleBackground color="#fff" className="animate-spin-slower" />
      </div>
      <Container className="relative">
        <div className="mx-auto max-w-md text-center">
          <h2 className="text-3xl font-medium tracking-tight text-white sm:text-4xl">
            {t('landing.cta.title')}
          </h2>
          <p className="mt-4 text-lg text-gray-300">
            {t('landing.cta.description')}
          </p>
          <div className="mt-8 flex justify-center">
            <a
              className="rounded bg-white p-3 font-medium text-violet-700"
              href={ENV.APP_URL}
              target={'_blank'}
              rel="noreferrer"
              id="cta_open-app"
            >
              <span>{t('landing.hero.cta')}</span>
            </a>
          </div>
        </div>
      </Container>
    </section>
  )
}
