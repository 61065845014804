import { forwardRef } from 'react'

import { BuildingStorefrontIcon } from '@heroicons/react/24/outline'

import clsx from 'clsx'
import Image from 'next/image'

import profileImage from '@/images/profile-image.png'

import { Logo } from './Logo'

function SalonIcon(props) {
  return (
    <div className="flex h-6 w-6 items-center justify-center rounded-full bg-violet-400">
      <BuildingStorefrontIcon
        className="h-4 w-4 flex-shrink-0  text-white"
        aria-hidden="true"
      />
    </div>
  )
}

function UserIcon(props) {
  return (
    <Image
      className="ml-2 h-6 w-6 rounded-full"
      width={40}
      height={40}
      src={profileImage}
      alt="Zdjęcie profilowe"
    />
  )
}

export function AppScreen({ children, className, ...props }) {
  return (
    <div className={clsx('flex flex-col', className)} {...props}>
      <div className="grid grid-cols-3 justify-items-center border-b border-gray-200 px-4 pb-3 pt-4">
        <div className="mr-auto text-sm text-gray-900">
          <span>SMS: 256</span>
        </div>
        <Logo className="h-6 w-[64px] flex-none fill-gray-900" />
        <div className="ml-auto flex">
          <SalonIcon className="h-6 w-6 flex-none" />
          <UserIcon className="h-6 w-6 flex-none" />
        </div>
      </div>
      {children}
    </div>
  )
}

AppScreen.Header = forwardRef(function AppScreenHeader({ children }, ref) {
  return <div ref={ref}>{children}</div>
})

AppScreen.Body = forwardRef(function AppScreenBody(
  { children, className },
  ref
) {
  return (
    <div ref={ref} className={clsx('relative bg-white', className)}>
      {children}
    </div>
  )
})
