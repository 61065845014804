import { Fragment, useEffect, useId, useRef, useState } from 'react'

import { Tab } from '@headlessui/react'

import {
  FunnelIcon,
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  PlusCircleIcon,
  Bars3Icon,
  CalendarDaysIcon,
  UsersIcon,
  EnvelopeIcon,
  TrashIcon,
  PlusIcon,
} from '@heroicons/react/24/outline'
import {
  ChatBubbleLeftEllipsisIcon,
  EnvelopeIcon as EnvelopeIconSolid,
} from '@heroicons/react/24/solid'

import clsx from 'clsx'
import add from 'date-fns/add'
import format from 'date-fns/format'
import isToday from 'date-fns/isToday'
import { enUS as en, pl, uk } from 'date-fns/locale'
import startOfWeek from 'date-fns/startOfWeek'
import { AnimatePresence, motion } from 'framer-motion'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useDebouncedCallback } from 'use-debounce'

import { AppScreen } from '@/components/AppScreen'
import { CircleBackground } from '@/components/CircleBackground'
import { Container } from '@/components/Container'
import { PhoneFrame } from '@/components/PhoneFrame'

const locales = { en, pl, uk }
const MotionAppScreenHeader = motion(AppScreen.Header)
const MotionAppScreenBody = motion(AppScreen.Body)

const features = [
  {
    name: 'landing.features.feature1.name',
    description: 'landing.features.feature1.description',
    icon: DeviceNotificationIcon,
    screen: CalendarScreen,
  },
  {
    name: 'landing.features.feature2.name',
    description: 'landing.features.feature2.description',
    icon: DeviceUserIcon,
    screen: MessagesScreen,
  },
  {
    name: 'landing.features.feature3.name',
    description: 'landing.features.feature3.description',
    icon: DeviceTouchIcon,
    screen: ManagementScreen,
  },
]

function DeviceUserIcon(props) {
  return (
    <svg viewBox="0 0 32 32" aria-hidden="true" {...props}>
      <circle cx={16} cy={16} r={16} fill="#fff" fillOpacity={0.2} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 23a3 3 0 100-6 3 3 0 000 6zm-1 2a4 4 0 00-4 4v1a2 2 0 002 2h6a2 2 0 002-2v-1a4 4 0 00-4-4h-2z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 4a4 4 0 014-4h14a4 4 0 014 4v24a4.002 4.002 0 01-3.01 3.877c-.535.136-.99-.325-.99-.877s.474-.98.959-1.244A2 2 0 0025 28V4a2 2 0 00-2-2h-1.382a1 1 0 00-.894.553l-.448.894a1 1 0 01-.894.553h-6.764a1 1 0 01-.894-.553l-.448-.894A1 1 0 0010.382 2H9a2 2 0 00-2 2v24a2 2 0 001.041 1.756C8.525 30.02 9 30.448 9 31s-.455 1.013-.99.877A4.002 4.002 0 015 28V4z"
        fill="#fff"
      />
    </svg>
  )
}

function DeviceNotificationIcon(props) {
  return (
    <svg viewBox="0 0 32 32" aria-hidden="true" {...props}>
      <circle cx={16} cy={16} r={16} fill="#fff" fillOpacity={0.2} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 0a4 4 0 00-4 4v24a4 4 0 004 4h14a4 4 0 004-4V4a4 4 0 00-4-4H9zm0 2a2 2 0 00-2 2v24a2 2 0 002 2h14a2 2 0 002-2V4a2 2 0 00-2-2h-1.382a1 1 0 00-.894.553l-.448.894a1 1 0 01-.894.553h-6.764a1 1 0 01-.894-.553l-.448-.894A1 1 0 0010.382 2H9z"
        fill="#fff"
      />
      <path
        d="M9 8a2 2 0 012-2h10a2 2 0 012 2v2a2 2 0 01-2 2H11a2 2 0 01-2-2V8z"
        fill="#fff"
      />
    </svg>
  )
}

function DeviceTouchIcon(props) {
  const id = useId()

  return (
    <svg viewBox="0 0 32 32" fill="none" aria-hidden="true" {...props}>
      <defs>
        <linearGradient
          id={`${id}-gradient`}
          x1={14}
          y1={14.5}
          x2={7}
          y2={17}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#D4D4D4" stopOpacity={0} />
        </linearGradient>
      </defs>
      <circle cx={16} cy={16} r={16} fill="#fff" fillOpacity={0.2} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 4a4 4 0 014-4h14a4 4 0 014 4v13h-2V4a2 2 0 00-2-2h-1.382a1 1 0 00-.894.553l-.448.894a1 1 0 01-.894.553h-6.764a1 1 0 01-.894-.553l-.448-.894A1 1 0 0010.382 2H9a2 2 0 00-2 2v24a2 2 0 002 2h4v2H9a4 4 0 01-4-4V4z"
        fill="#fff"
      />
      <path
        d="M7 22c0-4.694 3.5-8 8-8"
        stroke={`url(#${id}-gradient)`}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 20l.217-5.513a1.431 1.431 0 00-2.85-.226L17.5 21.5l-1.51-1.51a2.107 2.107 0 00-2.98 0 .024.024 0 00-.005.024l3.083 9.25A4 4 0 0019.883 32H25a4 4 0 004-4v-5a3 3 0 00-3-3h-5z"
        fill="#fff"
      />
    </svg>
  )
}

const headerAnimation = {
  initial: { opacity: 0, transition: { duration: 0.3 } },
  animate: { opacity: 1, transition: { duration: 0.3, delay: 0.3 } },
  exit: { opacity: 0, transition: { duration: 0.3 } },
}

const maxZIndex = 2147483647

const bodyVariantBackwards = {
  opacity: 0.4,
  scale: 0.8,
  zIndex: 0,
  filter: 'blur(4px)',
  zIndex: 0,
  transition: { duration: 0.4 },
}

const bodyVariantForwards = (custom) => ({
  y: '100%',
  zIndex: maxZIndex - custom.changeCount,
  transition: { duration: 0.4 },
})

const bodyAnimation = {
  initial: 'initial',
  animate: 'animate',
  exit: 'exit',
  variants: {
    initial: (custom) =>
      custom.isForwards ? bodyVariantForwards(custom) : bodyVariantBackwards,
    animate: (custom) => ({
      y: '0%',
      opacity: 1,
      scale: 1,
      zIndex: maxZIndex / 2 - custom.changeCount,
      filter: 'blur(0px)',
      transition: { duration: 0.4 },
    }),
    exit: (custom) =>
      custom.isForwards ? bodyVariantBackwards : bodyVariantForwards(custom),
  },
}

function CalendarScreen({ custom, animated = false }) {
  const router = useRouter()
  const { t } = useTranslation('landing')

  const START_OF_WEEK = startOfWeek(new Date(), { weekStartsOn: 1 })

  const formatWeekDay = (formatPattern, key) => {
    return format(
      add(START_OF_WEEK, {
        days: key,
      }),
      formatPattern,
      {
        locale: locales[router.locale],
      }
    )
  }

  const CALENDAR_WEEK = [...Array(7).keys()].map((key) => {
    return {
      dayName: formatWeekDay('EEEEE', key),
      dayNumber: formatWeekDay('d', key),
      hasAppointments: key == 2 || key === 3 || key === 4,
      isToday: isToday(
        add(START_OF_WEEK, {
          days: key,
        })
      ),
    }
  })

  const HOURS = [
    '11:30',
    '12:00',
    '12:30',
    '13:00',
    '13:30',
    '14:00',
    '14:30',
    '15:00',
    '15:30',
    '16:00',
    '16:30',
    '17:00',
    '17:30',
    '18:00',
    '18:30',
    '19:00',
    '19:30',
  ]

  return (
    <AppScreen className="w-full">
      <MotionAppScreenHeader {...(animated ? headerAnimation : {})}>
        <div className="flex px-4 py-2">
          <div className="mr-2 flex border px-3 py-2">
            <CalendarIcon
              className="mr-2 h-4 w-4 flex-shrink-0 self-center text-gray-700"
              aria-hidden="true"
            />
            <span className="text-sm capitalize text-gray-700">
              {format(new Date(), 'MMM, yyyy', {
                locale: locales[router.locale],
              })}
            </span>
          </div>
          <div className="flex border px-3 py-2">
            <FunnelIcon
              className="mr-2 h-4 w-4 flex-shrink-0 self-center text-gray-700"
              aria-hidden="true"
            />
            <span className="text-sm capitalize text-gray-700">
              {t('landing.mobileAppSample.filters')}
            </span>
          </div>
        </div>
        <div className="w-100 flex border-y bg-gray-100 pb-1">
          <div className="flex w-8">
            <ChevronLeftIcon
              className="mx-auto h-4 w-4 flex-shrink-0 self-center text-gray-700"
              aria-hidden="true"
            />
          </div>
          <div className="grid grow grid-cols-7 grid-rows-none">
            {CALENDAR_WEEK.map((day) => (
              <div className="flex flex-col" key={day.dayNumber}>
                <span className="text-center text-sm capitalize">
                  {day.dayName}
                </span>
                <span
                  className={clsx(
                    'text-center text-sm font-bold',
                    day.isToday
                      ? 'font-extrabold text-violet-500'
                      : 'text-gray-500'
                  )}
                >
                  {day.dayNumber}
                </span>
                {day.hasAppointments && (
                  <div className="mx-auto h-2 w-2 rounded-full bg-red-400"></div>
                )}
              </div>
            ))}
          </div>
          <div className="flex w-8">
            <ChevronRightIcon
              className="mx-auto mr-2 h-4 w-4 flex-shrink-0 self-center text-gray-700"
              aria-hidden="true"
            />
          </div>
        </div>
      </MotionAppScreenHeader>
      <MotionAppScreenBody {...(animated ? { ...bodyAnimation, custom } : {})}>
        <div className="relative bg-gray-200/75">
          {HOURS.map((hour, index) => (
            <div className="px-2 py-1 text-[12px] text-gray-500" key={hour}>
              <div
                className={clsx(
                  'flex border-b border-b-gray-300',
                  index % 2 == 0 ? 'border-dotted' : 'border-solid'
                )}
              >
                <span>{hour}</span>
              </div>
            </div>
          ))}
          <div className="absolute left-12 top-0 h-[78px] w-[269px] rounded-sm border-x-2 border-b-2 border-amber-200 bg-amber-50">
            <div className="h-4 bg-amber-200 pl-1 text-xs font-semibold text-amber-900">
              11:00 - 12:30
            </div>
            <div className="flex flex-col text-emerald-900">
              <span className="px-2 pt-2 text-[10px] font-bold uppercase">
                Honorata Wilk
              </span>
              <span className="px-2 text-[13px]">
                {t('landing.mobileAppSample.yourService')}
              </span>
            </div>
          </div>
          <div className="absolute left-12 top-[78px] h-[108px] w-[269px] rounded-sm border-x-2 border-b-2 border-purple-200 bg-purple-50">
            <div className="h-4 bg-purple-200 pl-1 text-xs font-semibold text-purple-900">
              12:30 - 14:30
            </div>
            <div className="flex flex-col text-emerald-900">
              <span className="px-2 pt-2 text-[10px] font-bold uppercase">
                Angelika Surma
              </span>
              <span className="px-2 text-[13px]">
                {t('landing.mobileAppSample.yourService')}
              </span>
            </div>
          </div>
          <div className="absolute left-12 top-[265px] h-[82px] w-[269px] rounded-sm border-x-2 border-b-2 border-cyan-200 bg-cyan-50">
            <div className="h-4 bg-cyan-200 pl-1 text-xs font-semibold text-cyan-900">
              16:00 - 17:30
            </div>
            <div className="flex flex-col text-emerald-900">
              <span className="px-2 pt-2 text-[10px] font-bold uppercase">
                Helena Wolanski
              </span>
              <span className="px-2 text-[13px]">
                {t('landing.mobileAppSample.yourService')}
              </span>
            </div>
          </div>
          <div className="absolute bottom-0 flex h-9 w-full justify-between border-t bg-white px-4 pt-2">
            <div className="flex flex-col text-gray-500">
              <Bars3Icon
                className="mt-1 h-6 w-6 flex-shrink-0 self-center "
                aria-hidden="true"
              />
              <span className="mt-0 block text-[9px]">
                {t('landing.mobileAppSample.menu')}
              </span>
            </div>
            <div className="flex flex-col text-violet-500">
              <CalendarDaysIcon
                className="mt-1 h-6 w-6 flex-shrink-0 self-center"
                aria-hidden="true"
              />
              <span className="mt-0 block text-[9px]">
                {t('landing.mobileAppSample.calendar')}
              </span>
            </div>
            <div className="flex flex-col text-gray-500">
              <PlusCircleIcon
                className="mt-1 h-6 w-6 flex-shrink-0 self-center "
                aria-hidden="true"
              />
              <span className="mt-0 block text-[9px]">
                {t('landing.mobileAppSample.add')}
              </span>
            </div>
            <div className="flex flex-col text-gray-500">
              <EnvelopeIcon
                className="mt-1 h-6 w-6 flex-shrink-0 self-center "
                aria-hidden="true"
              />
              <span className="mt-0 block text-[9px]">
                {t('landing.mobileAppSample.communication')}
              </span>
            </div>
            <div className="flex flex-col text-gray-500">
              <UsersIcon
                className="mt-1 h-6 w-6 flex-shrink-0 self-center "
                aria-hidden="true"
              />
              <span className="mt-0 block text-[9px]">
                {t('landing.mobileAppSample.clients')}
              </span>
            </div>
          </div>
        </div>
      </MotionAppScreenBody>
    </AppScreen>
  )
}

function MessagesScreen({ custom, animated = false }) {
  const { t } = useTranslation('landing')

  return (
    <AppScreen className="w-full">
      <MotionAppScreenHeader {...(animated ? headerAnimation : {})}>
        <div className="flex flex-col border-b px-4 pb-3 pt-2">
          <span className="text-lg font-semibold">
            {t('landing.mobileAppSample.smsTemplates.title')}
          </span>
          <span className="text-xs text-gray-500">
            {t('landing.mobileAppSample.smsTemplates.description')}
          </span>
        </div>
      </MotionAppScreenHeader>
      <MotionAppScreenBody {...(animated ? { ...bodyAnimation, custom } : {})}>
        <div className="relative">
          <div className="flex items-center justify-between border-b p-4">
            <span className="text-sm">
              {t('landing.mobileAppSample.smsTemplates.newAppointment')}
            </span>
            <ChevronRightIcon className="ml-2 h-4 w-4" aria-hidden="true" />
          </div>
          <div className="flex items-center justify-between border-b p-4">
            <span className="text-sm">
              {t('landing.mobileAppSample.smsTemplates.appointmentReminder')}
            </span>
            <ChevronRightIcon
              className="ml-2 h-4 w-4 rotate-90"
              aria-hidden="true"
            />
          </div>
          <div className="flex flex-col bg-gray-100 py-4">
            <div className="flex flex-wrap justify-between">
              <div className="mb-1 flex items-center justify-center rounded-sm border border-violet-500 bg-violet-500 px-2 py-1 text-white">
                <PlusIcon className="mr-1 h-3 w-3" aria-hidden="true" />
                <span className="text-xs">
                  {t('landing.mobileAppSample.smsTemplates.appointmentData')}
                </span>
              </div>
              <div className="mb-1 flex items-center justify-center rounded-sm border border-violet-500 bg-violet-500 px-2 py-1 text-white">
                <PlusIcon className="mr-1 h-3 w-3" aria-hidden="true" />
                <span className="text-xs">
                  {t('landing.mobileAppSample.smsTemplates.clientName')}
                </span>
              </div>
              <div className="mb-1 flex items-center justify-center rounded-sm border border-violet-500 bg-violet-500 px-2 py-1 text-white">
                <PlusIcon className="mr-1 h-3 w-3" aria-hidden="true" />
                <span className="text-xs">
                  {t('landing.mobileAppSample.smsTemplates.appointmentTime')}
                </span>
              </div>
              <div className="mb-1 flex items-center justify-center rounded-sm border border-violet-500 bg-violet-500 px-2 py-1 text-white">
                <PlusIcon className="mr-1 h-3 w-3" aria-hidden="true" />
                <span className="text-xs">
                  {t('landing.mobileAppSample.smsTemplates.cancelationLink')}
                </span>
              </div>
            </div>
            <div className="my-2 rounded-sm border bg-white p-2">
              <span className="text-sm">
                Beauty Salon: {t('landing.mobileAppSample.smsTemplates.hi')}{' '}
                <span className="text-violet-700">
                  {' '}
                  &#123;client_name&#125;
                </span>{' '}
                ,{' '}
                {t(
                  'landing.mobileAppSample.smsTemplates.reminderAboutReservation'
                )}
                <span className="text-violet-700">
                  {' '}
                  &#123;appointment_date&#125;
                </span>{' '}
                o
                <span className="text-violet-700">
                  {' '}
                  &#123;appointment_time&#125;
                </span>
                . {t('landing.mobileAppSample.smsTemplates.toCancel')}:
                <span className="text-violet-700">
                  {' '}
                  &#123;cancel_link&#125;
                </span>
              </span>
            </div>
            <div className="-mt-1 flex items-center justify-end px-2">
              <ChatBubbleLeftEllipsisIcon
                className="mr-1 h-[14px] w-[14px] text-gray-500"
                aria-hidden="true"
              />
              <span className="text-xs text-gray-500">105 / 160 •</span>
              <EnvelopeIconSolid className="ml-1 h-[14px] w-[14px] text-gray-500" />
              <span className="ml-1 text-xs text-gray-500">1</span>
            </div>
            <div className="mt-4 flex flex-col bg-gray-200/75 p-2 text-sm text-gray-600">
              <span className="mb-2 text-xs font-semibold uppercase text-gray-400">
                {t('landing.mobileAppSample.smsTemplates.sampleMessage')}:
              </span>
              <span>
                Beauty Salon: {t('landing.mobileAppSample.smsTemplates.hi')}{' '}
                Kasia,
                {t(
                  'landing.mobileAppSample.smsTemplates.reminderAboutReservation'
                )}{' '}
                {format(new Date(), 'dd/MM/yyyy')} o 14:00.{' '}
                {t('landing.mobileAppSample.smsTemplates.toCancel')}:
                https://estetify.pl/c/7pLJfXU
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between border-b p-4">
            <span className="text-sm">
              {t('landing.mobileAppSample.smsTemplates.updateAppointment')}
            </span>
            <ChevronRightIcon className="ml-2 h-4 w-4" aria-hidden="true" />
          </div>
          <div className="absolute bottom-[28px] flex h-16 w-full justify-between border-t bg-white px-4 pt-2">
            <div className="flex flex-col text-gray-500">
              <Bars3Icon
                className="mt-1 h-6 w-6 flex-shrink-0 self-center "
                aria-hidden="true"
              />
              <span className="mt-0 block text-[9px]">
                {t('landing.mobileAppSample.menu')}
              </span>
            </div>
            <div className="flex flex-col text-gray-500">
              <CalendarDaysIcon
                className="mt-1 h-6 w-6 flex-shrink-0 self-center"
                aria-hidden="true"
              />
              <span className="mt-0 block text-[9px]">
                {t('landing.mobileAppSample.calendar')}
              </span>
            </div>
            <div className="flex flex-col text-gray-500">
              <PlusCircleIcon
                className="mt-1 h-6 w-6 flex-shrink-0 self-center "
                aria-hidden="true"
              />
              <span className="mt-0 block text-[9px]">
                {t('landing.mobileAppSample.add')}
              </span>
            </div>
            <div className="flex flex-col text-violet-500">
              <EnvelopeIcon
                className="mt-1 h-6 w-6 flex-shrink-0 self-center "
                aria-hidden="true"
              />
              <span className="mt-0 block text-[9px]">
                {t('landing.mobileAppSample.communication')}
              </span>
            </div>
            <div className="flex flex-col text-gray-500">
              <UsersIcon
                className="mt-1 h-6 w-6 flex-shrink-0 self-center "
                aria-hidden="true"
              />
              <span className="mt-0 block text-[9px]">
                {t('landing.mobileAppSample.clients')}
              </span>
            </div>
          </div>
        </div>
      </MotionAppScreenBody>
    </AppScreen>
  )
}

function ManagementScreen({ custom, animated = false }) {
  const { t: gt } = useTranslation('generic')
  const { t } = useTranslation('landing')

  const DAYS = [
    { dayName: 'monday', isClosed: true, isIrregular: false },
    { dayName: 'tuesday', isClosed: false, isIrregular: false },
    { dayName: 'wednesday', isClosed: false, isIrregular: true },
    { dayName: 'thursday', isClosed: false, isIrregular: false },
  ]

  return (
    <AppScreen className="w-full">
      <MotionAppScreenHeader {...(animated ? headerAnimation : {})}>
        <div className="flex flex-col border-b px-4 pb-3 pt-2">
          <span className="text-lg font-semibold">
            {t('landing.mobileAppSample.openingHours.title')}
          </span>
          <span className="text-sm">
            {t('landing.mobileAppSample.openingHours.description')}
          </span>
        </div>
      </MotionAppScreenHeader>
      <MotionAppScreenBody {...(animated ? { ...bodyAnimation, custom } : {})}>
        <div className="relative h-[530px] bg-white">
          {DAYS.map((day, index) => (
            <div key={index}>
              <div className="flex items-center justify-between border-b px-4 py-5">
                <span className="text-sm">
                  {gt(`generic.weekdays.${day.dayName}`)}
                </span>
                <div className="flex items-center">
                  <div
                    className={clsx(
                      'flex h-[16px] w-7 items-center rounded-xl',
                      day.isClosed === true
                        ? 'justify-end bg-violet-500'
                        : 'justify-start bg-gray-300'
                    )}
                  >
                    <div className="mx-[1px] h-[14px] w-[14px] rounded-full bg-white"></div>
                  </div>
                  <span className="ml-2 text-sm">
                    {t('landing.mobileAppSample.closed')}
                  </span>
                </div>
              </div>
              {day.isClosed === false && day.isIrregular === false && (
                <div className="flex items-center justify-start bg-gray-200/75 px-2 py-4">
                  <div className="rounded-sm border bg-white px-3 py-2">
                    <span className="text-sm">10:00</span>
                  </div>
                  <span className="mx-2">-</span>
                  <div className="rounded-sm border bg-white px-3 py-2">
                    <span className="text-sm">18:00</span>
                  </div>
                </div>
              )}
              {day.isIrregular === true && (
                <div>
                  <div className="flex items-center justify-start bg-gray-200/75 px-2 py-4">
                    <div className="rounded-sm border bg-white px-3 py-2">
                      <span className="text-sm">10:00</span>
                    </div>
                    <span className="mx-2">-</span>
                    <div className="rounded-sm border bg-white px-3 py-2">
                      <span className="text-sm">12:00</span>
                    </div>
                  </div>
                  <div className="flex justify-between bg-gray-200/75 px-2 pb-4">
                    <div className="flex items-center justify-start">
                      <div className="rounded-sm border bg-white px-3 py-2">
                        <span className="text-sm">14:00</span>
                      </div>
                      <span className="mx-2">-</span>
                      <div className="rounded-sm border bg-white px-3 py-2">
                        <span className="text-sm">16:00</span>
                      </div>
                    </div>
                    <TrashIcon
                      className="mr-2 h-4 w-4 flex-shrink-0 self-center text-red-500"
                      aria-hidden="true"
                    />
                  </div>
                </div>
              )}
            </div>
          ))}
          <div className="absolute bottom-0 flex h-16 w-full justify-between border-t bg-white px-4 pb-2 pt-2">
            <div className="flex flex-col text-gray-500">
              <Bars3Icon
                className="mt-1 h-6 w-6 flex-shrink-0 self-center "
                aria-hidden="true"
              />
              <span className="mt-0 block text-[9px]">
                {t('landing.mobileAppSample.menu')}
              </span>
            </div>
            <div className="flex flex-col text-gray-500">
              <CalendarDaysIcon
                className="mt-1 h-6 w-6 flex-shrink-0 self-center"
                aria-hidden="true"
              />
              <span className="mt-0 block text-[9px]">
                {t('landing.mobileAppSample.calendar')}
              </span>
            </div>
            <div className="flex flex-col text-gray-500">
              <PlusCircleIcon
                className="mt-1 h-6 w-6 flex-shrink-0 self-center "
                aria-hidden="true"
              />
              <span className="mt-0 block text-[9px]">
                {t('landing.mobileAppSample.add')}
              </span>
            </div>
            <div className="flex flex-col text-gray-500">
              <EnvelopeIcon
                className="mt-1 h-6 w-6 flex-shrink-0 self-center "
                aria-hidden="true"
              />
              <span className="mt-0 block text-[9px]">
                {t('landing.mobileAppSample.communication')}
              </span>
            </div>
            <div className="flex flex-col text-gray-500">
              <UsersIcon
                className="mt-1 h-6 w-6 flex-shrink-0 self-center "
                aria-hidden="true"
              />
              <span className="mt-0 block text-[9px]">
                {t('landing.mobileAppSample.clients')}
              </span>
            </div>
          </div>
        </div>
      </MotionAppScreenBody>
    </AppScreen>
  )
}

function usePrevious(value) {
  const ref = useRef()

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}

function FeaturesDesktop() {
  const { t } = useTranslation('landing')
  const [changeCount, setChangeCount] = useState(0)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const prevIndex = usePrevious(selectedIndex)
  const isForwards = prevIndex === undefined ? true : selectedIndex > prevIndex

  const onChange = useDebouncedCallback(
    (selectedIndex) => {
      setSelectedIndex(selectedIndex)
      setChangeCount((changeCount) => changeCount + 1)
    },
    100,
    { leading: true }
  )

  return (
    <Tab.Group
      as="div"
      className="grid grid-cols-12 items-center gap-8 lg:gap-16 xl:gap-24"
      selectedIndex={selectedIndex}
      onChange={onChange}
      vertical
    >
      <Tab.List className="relative z-10 order-last col-span-6 space-y-6">
        {features.map((feature, featureIndex) => (
          <div
            key={feature.name}
            className="relative rounded-2xl transition-colors hover:bg-violet-800/30"
          >
            {featureIndex === selectedIndex && (
              <motion.div
                layoutId="activeBackground"
                className="absolute inset-0 bg-violet-800"
                initial={{ borderRadius: 16 }}
              />
            )}
            <div className="relative z-10 p-8">
              <feature.icon className="h-8 w-8" />
              <h3 className="mt-6 text-lg font-semibold text-white">
                <Tab className="text-left [&:not(:focus-visible)]:focus:outline-none">
                  <span className="absolute inset-0 rounded-2xl" />
                  {t(feature.name)}
                </Tab>
              </h3>
              <p className="mt-2 text-sm text-violet-200">
                {t(feature.description)}
              </p>
            </div>
          </div>
        ))}
      </Tab.List>
      <div className="relative col-span-6">
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
          <CircleBackground color="#fff" className="animate-spin-slower" />
        </div>
        <PhoneFrame className="z-10 mx-auto w-full max-w-[366px]">
          <Tab.Panels as={Fragment}>
            <AnimatePresence
              initial={false}
              custom={{ isForwards, changeCount }}
            >
              {features.map((feature, featureIndex) =>
                selectedIndex === featureIndex ? (
                  <Tab.Panel
                    static
                    key={feature.name + changeCount}
                    className="col-start-1 row-start-1 flex focus:outline-offset-[32px] [&:not(:focus-visible)]:focus:outline-none"
                  >
                    <feature.screen
                      animated
                      custom={{ isForwards, changeCount }}
                    />
                  </Tab.Panel>
                ) : null
              )}
            </AnimatePresence>
          </Tab.Panels>
        </PhoneFrame>
      </div>
    </Tab.Group>
  )
}

function FeaturesMobile() {
  const { t } = useTranslation('landing')
  const [activeIndex, setActiveIndex] = useState(0)
  const slideContainerRef = useRef()
  const slideRefs = useRef([])

  useEffect(() => {
    const observer = new window.IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            setActiveIndex(slideRefs.current.indexOf(entry.target))
            break
          }
        }
      },
      {
        root: slideContainerRef.current,
        threshold: 0.6,
      }
    )

    for (const slide of slideRefs.current) {
      if (slide) {
        observer.observe(slide)
      }
    }

    return () => {
      observer.disconnect()
    }
  }, [slideContainerRef, slideRefs])

  return (
    <>
      <div
        ref={slideContainerRef}
        className="-mb-4 flex snap-x snap-mandatory -space-x-4 overflow-x-auto overscroll-x-contain scroll-smooth pb-4 [scrollbar-width:none] sm:-space-x-6 [&::-webkit-scrollbar]:hidden"
      >
        {features.map((feature, featureIndex) => (
          <div
            key={featureIndex}
            ref={(ref) => (slideRefs.current[featureIndex] = ref)}
            className="w-full flex-none snap-center px-4 sm:px-6"
          >
            <div className="relative transform overflow-hidden rounded-2xl bg-violet-800 px-5 py-6">
              <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                <CircleBackground
                  color="#fff"
                  className={featureIndex % 2 === 1 ? 'rotate-180' : undefined}
                />
              </div>
              <PhoneFrame className="relative mx-auto w-full max-w-[366px]">
                <feature.screen />
              </PhoneFrame>
              <div className="absolute inset-x-0 bottom-0 bg-violet-800/95 p-6 backdrop-blur sm:p-10">
                <feature.icon className="h-8 w-8" />
                <h3 className="mt-6 text-sm font-semibold text-white sm:text-lg">
                  {t(feature.name)}
                </h3>
                <p className="mt-2 text-sm text-violet-400">
                  {t(feature.description)}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-6 flex justify-center gap-3">
        {features.map((_, featureIndex) => (
          <button
            type="button"
            key={featureIndex}
            className={clsx(
              'relative h-0.5 w-4 rounded-full',
              featureIndex === activeIndex ? 'bg-gray-300' : 'bg-gray-500'
            )}
            aria-label={`Go to slide ${featureIndex + 1}`}
            onClick={() => {
              slideRefs.current[featureIndex].scrollIntoView({
                block: 'nearest',
                inline: 'nearest',
              })
            }}
          >
            <span className="absolute -inset-x-1.5 -inset-y-3" />
          </button>
        ))}
      </div>
    </>
  )
}

export const PrimaryFeatures = () => {
  const { t } = useTranslation('landing')

  return (
    <section id="features" className="bg-violet-700 py-20 sm:py-32">
      <Container>
        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-3xl">
          <h2 className="text-3xl font-medium tracking-tight text-white">
            {t('landing.features.title')}
          </h2>
          <p className="mt-2 text-lg text-violet-200">
            {t('landing.features.description')}
          </p>
        </div>
      </Container>
      <div className="mt-16 md:hidden">
        <FeaturesMobile />
      </div>
      <Container className="hidden md:mt-20 md:block">
        <FeaturesDesktop />
      </Container>
    </section>
  )
}
