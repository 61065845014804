import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import Link from 'next/link'
import ContactUsImage from 'public/assets/images/szymon.jpeg'

import { Container } from '@/components/Container'
import { IDS } from '@/constants/ids'
import { CONTACT } from '@/features/contact/constants/contact'

export const ContactUsSection = () => {
  const { t } = useTranslation('landing')

  return (
    <Container>
      <section
        className="m-auto my-16 flex flex-col-reverse items-center gap-8 md:flex-row"
        aria-describedby={IDS.CONTACT_US}
      >
        <div>
          <h2
            className="text-3xl font-bold tracking-tight text-violet-950 sm:text-4xl"
            id={IDS.CONTACT_US}
          >
            {t('landing.contact.title')}
          </h2>
          <p className="mt-6 text-lg leading-8 text-violet-950">
            {t('landing.contact.description')}
          </p>
          <ul className="grid gap-2 py-2">
            {CONTACT.map((item) => (
              <li key={item.id}>
                <Link
                  target="_blank"
                  href={item.href}
                  className="inline-flex max-w-fit items-center gap-4 rounded-md px-2 py-1 transition-all hover:bg-violet-50/90 hover:outline hover:outline-violet-900/20"
                >
                  <item.icon className="h-5 w-5" /> {item.text}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <figure className="relative mt-16 lg:mt-8">
          <Image
            className="max-h-[20rem] w-[35rem] max-w-full rounded-md object-cover ring-1 ring-white/10"
            src={ContactUsImage}
            alt=""
          />
          <figcaption className="grid gap-4 pt-4">
            <h2 className="text-2xl font-bold tracking-tight">
              {t('landing.contact.founder.name')}
            </h2>
            <p className="text-violet-950/80">
              {t('landing.contact.founder.description')}
            </p>
            <div className="flex gap-4 pt-2">
              {CONTACT.map((item) => (
                <li
                  key={item.id}
                  className="inline-flex max-w-fit items-center gap-4 rounded-md px-2 py-1 transition-all hover:bg-violet-50/90 hover:outline hover:outline-violet-900/20"
                >
                  <Link target="_blank" href={item.href}>
                    <item.icon className="h-5 w-5" />{' '}
                    <div className="sr-only">{item.text}</div>
                  </Link>
                </li>
              ))}
            </div>
          </figcaption>
        </figure>
      </section>
    </Container>
  )
}
