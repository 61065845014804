import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/outline'
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/solid'

export const CONTACT = [
  {
    id: 'phone',
    icon: PhoneIcon,
    href: 'tel:48790254274',
    text: '(+48) 790 254 274 • Pon-Pt 9:00 do 17:00 (CET)',
  },
  {
    id: 'email',
    icon: EnvelopeIcon,
    href: 'mailto:biuro@estetify.pl',
    text: 'biuro@estetify.pl',
  },
  {
    id: 'facebook',
    icon: ChatBubbleBottomCenterTextIcon,
    href: 'https://www.facebook.com/estetify',
    text: 'Estetify.pl - prosty kalendarz dla branży beauty',
  },
] as const
