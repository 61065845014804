import { ArrowUpRightIcon } from '@heroicons/react/24/outline'

import Link from 'next/link'

type KeyFeatureWithPreviewProps = {
  children?: React.ReactNode
  title: React.ReactNode
  description?: React.ReactNode
  label?: React.ReactNode
  href?: string
}

export const KeyFeatureWithPreview = ({
  children,
  title,
  description,
  label,
  href,
}: KeyFeatureWithPreviewProps) => {
  return (
    <Link href={href ?? '#'}>
      <article className="group z-0 mt-auto h-full justify-between gap-4 rounded border border-violet-100 from-violet-100/5 via-violet-50/50 to-violet-100/5 shadow-sm outline-violet-100 transition-all hover:z-10 hover:scale-105 hover:bg-gray-100 hover:bg-gradient-to-tr hover:shadow-lg hover:outline">
        <div className="relative">
          <div className="flex flex-col-reverse justify-between gap-4 p-8 md:flex-row">
            <div className="flex flex-col gap-4">
              <div>
                {label && (
                  <div className="max-w-fit rounded border border-violet-700 bg-violet-200 p-1 px-2 text-xs tracking-wider text-violet-700">
                    <p className="font-bold uppercase">{label}</p>
                  </div>
                )}
                <h3 className="mt-2 max-w-3xl text-2xl font-medium tracking-tight text-violet-900/90 sm:text-4xl">
                  {title}
                </h3>
              </div>
              {description && (
                <p className="text max-w-3xl leading-8 text-violet-950/70">
                  {description}
                </p>
              )}
            </div>
            {href && (
              <div>
                <div className="ml-auto hidden max-w-fit rounded border p-2 text-violet-700 transition-all group-hover:bg-violet-700 group-hover:text-violet-100 md:block">
                  <ArrowUpRightIcon className="h-5 w-5" />
                </div>
              </div>
            )}
          </div>
        </div>
        {children && children}
      </article>
    </Link>
  )
}
