import { useState } from 'react'

import { RadioGroup } from '@headlessui/react'

import { CheckIcon, EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline'

import clsx from 'clsx'
import { useTranslation } from 'next-i18next'

import { Container } from '@/components/Container'
import { Logomark } from '@/components/Logo'
import { ENV } from '@/constants/env'

type Plan = {
  name: string
  featured: boolean
  compared: boolean
  description: string
  price: { monthly: string; annually: string }
  netPrice: { monthly: string; annually: string }
  button: { label: string; href: string }
  mainFeatures?: { id: number; value: string }[]
}

const Plan = ({
  plan,
  activePeriod,
}: {
  plan: Plan
  activePeriod: 'monthly' | 'annually'
}) => {
  const { t: tl } = useTranslation('landing')
  const { t: t } = useTranslation('pricing')

  return (
    <section
      className={clsx(
        'flex flex-col overflow-hidden rounded p-6 shadow-2xl shadow-violet-200/50',
        plan.featured
          ? 'order-first border border-violet-200 bg-white shadow-2xl shadow-violet-300/50 lg:order-none'
          : 'bg-white'
      )}
    >
      <h3 className="flex items-center text-sm font-semibold">
        <Logomark className={clsx('h-6 w-6 flex-none', 'fill-white')} />
        <span className="ml-4">{plan.name}</span>
      </h3>
      <p className="relative mt-5 flex text-3xl tracking-tight">
        {plan.price.monthly === plan.price.annually ? (
          plan.price.monthly
        ) : (
          <>
            <span
              aria-hidden={activePeriod === 'annually'}
              className={clsx(
                'transition duration-300',
                activePeriod === 'annually' &&
                  'pointer-events-none translate-x-6 select-none opacity-0'
              )}
            >
              {plan.price.monthly}
            </span>
            <span
              aria-hidden={activePeriod === 'monthly'}
              className={clsx(
                'absolute left-0 top-0 transition duration-300',
                activePeriod === 'monthly' &&
                  'pointer-events-none -translate-x-6 select-none opacity-0'
              )}
            >
              {plan.price.annually}{' '}
              <span className="text-sm text-violet-700">
                ({tl('landing.pricing.youSave')} 44%)
              </span>
            </span>
          </>
        )}
      </p>
      {plan.netPrice.monthly && activePeriod === 'monthly' && (
        <p className="mt-1 text-sm lowercase text-violet-950/70">
          {plan.netPrice.monthly}
        </p>
      )}
      {plan.netPrice.annually && activePeriod === 'annually' && (
        <p className="mt-1 text-sm lowercase text-violet-950/70">
          {plan.netPrice.annually}
        </p>
      )}
      <p className="mt-3 text-sm text-violet-950/80">{plan.description}</p>
      <div className="order-last mt-6">
        <ul
          role="list"
          className="-my-2 divide-y divide-violet-200 text-sm text-violet-950/80"
        >
          {plan.mainFeatures?.map((feature) => (
            <li key={feature.id} className="flex py-2">
              <CheckIcon className="h-6 w-6 flex-none text-violet-700" />
              <span className="ml-4">{feature.value}</span>
            </li>
          ))}
        </ul>
      </div>
      {plan.name === 'Business' && (
        <div className="mt-6">
          <div className="flex">
            <div className="flex-shrink-0">
              <PhoneIcon
                className="mt-[3px] h-5 w-5 text-violet-950/70"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3 text-sm text-violet-950/70">
              <a href="tel:+48790254274">(+48) 790 254 274</a>
              <p className="mt-1">{t('pricing.tiers.business.contactHours')}</p>
            </div>
          </div>
          <div className="mt-3 flex">
            <div className="flex-shrink-0">
              <EnvelopeIcon
                className="mt-[3px] h-5 w-5 text-violet-950/70"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3 text-sm text-violet-950/70">
              <p>biuro@estetify.pl</p>
            </div>
          </div>
        </div>
      )}
      <a
        href={plan.button.href}
        target={'_blank'}
        rel="noreferrer"
        className="bt-transparent text-md mt-6 flex cursor-pointer items-center justify-center whitespace-nowrap rounded border border-violet-600 px-4 py-2 font-medium leading-6 text-violet-600 shadow-sm transition-all hover:bg-violet-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-violet-700 focus:ring-offset-2 disabled:cursor-not-allowed disabled:border-violet-700 disabled:bg-violet-300 disabled:text-violet-500 disabled:opacity-30"
        id="pricing_open-app"
      >
        {plan.button.label}
      </a>
    </section>
  )
}

const EMAIL = process.env.NEXT_PUBLIC_CONTACT_EMAIL

export const PricingSection = () => {
  const { t: tl } = useTranslation('landing')
  const { t: tg } = useTranslation('generic')
  const { t } = useTranslation('pricing')
  const [activePeriod, setActivePeriod] = useState<'monthly' | 'annually'>(
    'monthly'
  )

  const plans: Plan[] = [
    {
      name: t('pricing.tiers.salon.name'),
      featured: true,
      compared: true,
      description: t('pricing.tiers.salon.description'),
      price: {
        monthly: `149 zł / ${tg('generic.monthAbbreviation')}`,
        annually: `990 zł / ${tg('generic.year')}`,
      },
      netPrice: {
        monthly: `121,14 zł ${tg('generic.net')}`,
        annually: `804,88 zł ${tg('generic.net')}`,
      },
      button: {
        label: t('pricing.tiers.salon.cta.landing'),
        href: ENV.APP_URL,
      },
      mainFeatures: [
        { id: 0, value: t('pricing.tiers.salon.mainFeatures.0') },
        { id: 1, value: t('pricing.tiers.salon.mainFeatures.1') },
        { id: 2, value: t('pricing.tiers.salon.mainFeatures.2') },
        { id: 3, value: t('pricing.tiers.salon.mainFeatures.3') },
        { id: 4, value: t('pricing.tiers.salon.mainFeatures.4') },
        { id: 5, value: t('pricing.tiers.salon.mainFeatures.5') },
        { id: 6, value: t('pricing.tiers.salon.mainFeatures.6') },
        { id: 7, value: t('pricing.tiers.salon.mainFeatures.7') },
        { id: 8, value: t('pricing.tiers.salon.mainFeatures.8') },
        { id: 9, value: t('pricing.tiers.salon.mainFeatures.9') },
      ],
    },
    {
      name: t('pricing.tiers.business.name'),
      featured: false,
      description: t('pricing.tiers.business.description'),
      compared: false,
      price: {
        monthly: t('pricing.forNegotation'),
        annually: t('pricing.forNegotation'),
      },
      netPrice: { monthly: '', annually: '' },
      button: {
        label: t('pricing.tiers.business.cta.landing'),
        href: `mailto:${EMAIL}?subject=${t('pricing.emailTitle')}&body=${t(
          'pricing.emailBody'
        )}`,
      },
    },
  ]

  return (
    <section
      id="pricing"
      aria-labelledby="pricing-title"
      className="border-t border-violet-200 py-20 sm:py-32"
    >
      <Container>
        <div className="mx-auto max-w-2xl text-center">
          <h2
            id="pricing-title"
            className="text-3xl font-medium tracking-tight"
          >
            {t('pricing.title')}
          </h2>
          <p className="mt-2 text-lg text-violet-950/80">
            {t('pricing.description')}
          </p>
        </div>

        <div className="mt-8 flex justify-center">
          <div className="relative">
            <RadioGroup
              value={activePeriod}
              onChange={setActivePeriod}
              className="grid grid-cols-2"
            >
              {['monthly', 'annually'].map((period) => (
                <RadioGroup.Option
                  key={period}
                  value={period}
                  className={clsx(
                    'cursor-pointer border border-violet-300/50 px-[calc(theme(spacing.3)-1px)] py-[calc(theme(spacing.2)-1px)] text-center text-sm text-violet-950/80 outline-2 outline-offset-2 transition-colors hover:border-violet-400',
                    period === 'monthly' ? 'rounded-sm' : '-ml-px rounded-sm'
                  )}
                >
                  {period === 'monthly'
                    ? tl('landing.pricing.monthly')
                    : tl('landing.pricing.annually')}
                </RadioGroup.Option>
              ))}
            </RadioGroup>
            <div
              aria-hidden="true"
              className={clsx(
                'pointer-events-none absolute inset-0 z-10 grid grid-cols-2 overflow-hidden rounded-sm bg-violet-700 transition-all duration-300',
                activePeriod === 'monthly'
                  ? '[clip-path:inset(0_50%_0_0)]'
                  : '[clip-path:inset(0_0_0_calc(50%-1px))]'
              )}
            >
              {['monthly', 'annually'].map((period) => (
                <div
                  key={period}
                  className={clsx(
                    'py-2 text-center text-sm font-semibold text-white [&:not(:focus-visible)]:focus:outline-none',
                    period === 'annually' && '-ml-px'
                  )}
                >
                  {period === 'monthly'
                    ? tl('landing.pricing.monthly')
                    : tl('landing.pricing.annually')}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-10 sm:mt-20 lg:max-w-none lg:grid-cols-2">
          {plans.map((plan) => (
            <Plan key={plan.name} plan={plan} activePeriod={activePeriod} />
          ))}
        </div>
      </Container>
    </section>
  )
}
