import { useId } from 'react'

import {
  CalendarIcon,
  FunnelIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline'

import clsx from 'clsx'
import add from 'date-fns/add'
import format from 'date-fns/format'
import isToday from 'date-fns/isToday'
import { enUS as en, pl, uk } from 'date-fns/locale'
import startOfWeek from 'date-fns/startOfWeek'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { AppScreen } from '@/components/AppScreen'
import { Button } from '@/components/Button'
import { Container } from '@/components/Container'
import { PhoneFrame } from '@/components/PhoneFrame'
import { ENV } from '@/constants/env'
import employeeOne from '@/images/employee-1.png'
import employeeTwo from '@/images/employee-2.png'

const locales = { en, pl, uk }

function BackgroundIllustration(props) {
  const id = useId()

  return (
    <div {...props}>
      <svg
        viewBox="0 0 1026 1026"
        fill="none"
        aria-hidden="true"
        className="absolute inset-0 h-full w-full animate-spin-slow"
      >
        <path
          d="M1025 513c0 282.77-229.23 512-512 512S1 795.77 1 513 230.23 1 513 1s512 229.23 512 512Z"
          stroke="#D4D4D4"
          strokeOpacity="0.7"
        />
        <path
          d="M513 1025C230.23 1025 1 795.77 1 513"
          stroke={`url(#${id}-gradient-1)`}
          strokeLinecap="round"
        />
        <defs>
          <linearGradient
            id={`${id}-gradient-1`}
            x1="1"
            y1="513"
            x2="1"
            y2="1025"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#8b5cf6" />
            <stop offset="1" stopColor="#8b5cf6" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
      <svg
        viewBox="0 0 1026 1026"
        fill="none"
        aria-hidden="true"
        className="absolute inset-0 h-full w-full animate-spin-reverse-slower"
      >
        <path
          d="M913 513c0 220.914-179.086 400-400 400S113 733.914 113 513s179.086-400 400-400 400 179.086 400 400Z"
          stroke="#D4D4D4"
          strokeOpacity="0.7"
        />
        <path
          d="M913 513c0 220.914-179.086 400-400 400"
          stroke={`url(#${id}-gradient-2)`}
          strokeLinecap="round"
        />
        <defs>
          <linearGradient
            id={`${id}-gradient-2`}
            x1="913"
            y1="513"
            x2="913"
            y2="913"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#8b5cf6" />
            <stop offset="1" stopColor="#8b5cf6" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  )
}

function AppDemo() {
  const router = useRouter()
  const { t } = useTranslation('landing')
  const START_OF_WEEK = startOfWeek(new Date(), { weekStartsOn: 1 })

  const formatWeekDay = (formatPattern, key) => {
    return format(
      add(START_OF_WEEK, {
        days: key,
      }),
      formatPattern,
      {
        locale: locales[router.locale],
      }
    )
  }

  const CALENDAR_WEEK = [...Array(7).keys()].map((key) => {
    return {
      dayName: formatWeekDay('EEEEE', key),
      dayNumber: formatWeekDay('d', key),
      hasAppointments: key < 1 || key === 3,
      isToday: isToday(
        add(START_OF_WEEK, {
          days: key,
        })
      ),
    }
  })

  const HOURS = [
    '08:30',
    '09:00',
    '09:30',
    '10:00',
    '10:30',
    '11:00',
    '11:30',
    '12:00',
    '12:30',
    '13:00',
    '13:30',
    '14:00',
  ]

  return (
    <AppScreen>
      <AppScreen.Body>
        <div className="flex px-4 py-2">
          <div className="mr-2 flex border px-3 py-2">
            <CalendarIcon
              className="mr-2 h-4 w-4 flex-shrink-0 self-center text-gray-700"
              aria-hidden="true"
            />
            <span className="text-sm capitalize text-gray-700">
              {format(new Date(), 'MMM, yyyy', {
                locale: locales[router.locale],
              })}
            </span>
          </div>
          <div className="flex border px-3 py-2">
            <FunnelIcon
              className="mr-2 h-4 w-4 flex-shrink-0 self-center text-gray-700"
              aria-hidden="true"
            />
            <span className="text-sm capitalize text-gray-700">
              {t('landing.mobileAppSample.filters')}
            </span>
          </div>
        </div>
        <div className="w-100 flex border-y bg-gray-100 pb-1">
          <div className="flex w-8">
            <ChevronLeftIcon
              className="mx-auto h-4 w-4 flex-shrink-0 self-center text-gray-700"
              aria-hidden="true"
            />
          </div>
          <div className="grid grow grid-cols-7 grid-rows-none">
            {CALENDAR_WEEK.map((day) => (
              <div className="flex flex-col" key={day.dayNumber}>
                <span className="text-center text-sm capitalize">
                  {day.dayName}
                </span>
                <span
                  className={clsx(
                    'text-center text-sm font-bold',
                    day.isToday
                      ? 'font-extrabold text-violet-500'
                      : 'text-gray-500'
                  )}
                >
                  {day.dayNumber}
                </span>
                {day.hasAppointments && (
                  <div className="mx-auto h-2 w-2 rounded-full bg-red-400"></div>
                )}
              </div>
            ))}
          </div>
          <div className="flex w-8">
            <ChevronRightIcon
              className="mx-auto mr-2 h-4 w-4 flex-shrink-0 self-center text-gray-700"
              aria-hidden="true"
            />
          </div>
        </div>
        <div className="inline-flex overflow-hidden border-b bg-gray-100 pl-12 text-sm">
          <div className="flex w-40 items-center border-x px-4 py-3">
            <Image
              className="h-7 w-7 rounded-sm"
              width={40}
              height={40}
              src={employeeOne}
              priority
              alt="Zdjęcie pracownika"
            />
            <span className="mx-2">Maria</span>
            <ChevronRightIcon
              className="ml-auto h-4 w-4 flex-shrink-0 self-center text-gray-700"
              aria-hidden="true"
            />
          </div>
          <div className="flex w-56 items-center border-r px-4 py-3">
            <Image
              className="h-7 w-7 rounded-sm"
              width={40}
              height={40}
              src={employeeTwo}
              priority
              alt="Zdjęcie pracownika"
            />
            <span className="mx-2">Kaja</span>
            <ChevronRightIcon
              className="mx-auto h-4 w-4 flex-shrink-0 self-center text-gray-700"
              aria-hidden="true"
            />
          </div>
        </div>
        <div className="relative bg-gray-200">
          {HOURS.map((hour, index) => (
            <div className="px-2 py-1 text-[12px] text-gray-500" key={hour}>
              <div
                className={clsx(
                  'flex border-b border-b-gray-300',
                  index % 2 == 0 ? 'border-dotted' : 'border-solid'
                )}
              >
                <span>{hour}</span>
              </div>
            </div>
          ))}
          <div className="absolute left-12 top-0 h-[78px] w-40 rounded-sm border-x-2 border-b-2 border-emerald-200 bg-emerald-50">
            <div className="h-4 bg-emerald-200 pl-1 text-xs font-semibold text-emerald-900">
              08:00 - 09:30
            </div>
            <div className="flex flex-col text-emerald-900">
              <span className="px-2 pt-2 text-[10px] font-bold uppercase">
                Edyta Nowak
              </span>
              <span className="px-2 text-[13px]">
                {t('landing.mobileAppSample.hybridManicure')}
              </span>
            </div>
          </div>
          <div className="absolute left-12 top-[78px] h-[105px] w-40 rounded-sm border-x-2 border-b-2 border-pink-200 bg-pink-50">
            <div className="h-4 bg-pink-200 pl-2 text-xs font-semibold text-pink-900">
              09:30 - 11:30
            </div>
            <div className="flex flex-col text-pink-900">
              <span className="px-2 pt-2 text-[10px] font-bold uppercase">
                Weronika Benke
              </span>
              <span className="px-2 text-[13px]">
                {t('landing.mobileAppSample.SpaCosmeticPedicure')}
              </span>
            </div>
          </div>
          <div className="absolute left-12 top-[183px] h-[105px] w-40 rounded-sm border-x-2 border-b-2 border-indigo-200 bg-indigo-50">
            <div className="h-4 bg-indigo-200 pl-2 text-xs font-semibold text-indigo-900">
              11:30 - 13:00
            </div>
            <div className="flex flex-col text-indigo-900">
              <span className="px-2 pt-2 text-[10px] font-bold uppercase">
                Magdalena Marek
              </span>
              <span className="px-2 text-[13px]">
                {t('landing.mobileAppSample.gelRemoval')}
              </span>
            </div>
          </div>
          <div className="absolute left-[207px] top-[50px] h-[238px] w-40 rounded-sm border-x-2 border-b-2 border-sky-200 bg-sky-50">
            <div className="h-4 bg-sky-200 pl-2 text-xs font-semibold text-sky-900">
              09:00 - 13:00
            </div>
            <div className="flex flex-col text-sky-900">
              <span className="px-2 pt-2 text-[10px] font-bold uppercase">
                Maria Kowalska
              </span>
              <span className="px-2 text-[13px]">
                {t('landing.mobileAppSample.airTouch')}
              </span>
            </div>
          </div>
        </div>
      </AppScreen.Body>
    </AppScreen>
  )
}

export function Hero() {
  const { t } = useTranslation('landing')

  return (
    <div className="lg:pb-30 overflow-hidden py-36">
      <Container>
        <div className="lg:grid lg:grid-cols-12 lg:gap-x-8 lg:gap-y-6">
          <div className="relative z-10 mx-auto max-w-2xl text-center lg:col-span-7 lg:max-w-none lg:pb-12 lg:pt-16 lg:text-left xl:col-span-6">
            <h1 className="text-5xl font-bold tracking-tight text-violet-950">
              {t('landing.hero.title')}
            </h1>
            <p className="mt-6 text-lg text-violet-950/80">
              {t('landing.hero.description')}
            </p>
            <div className="mt-8 flex flex-wrap justify-center gap-x-6 gap-y-4 lg:justify-start">
              <Link
                href={ENV.APP_URL}
                target={'_blank'}
                rel="noreferrer"
                id="hero_open-app"
              >
                <Button>
                  <span>{t('landing.hero.cta')}</span>
                </Button>
              </Link>
            </div>
            <p className="pt-4 text-sm tracking-wider text-violet-950">
              {t('landing.hero.subtext')}
            </p>
          </div>
          <div className="relative mt-10 sm:mt-20 lg:col-span-5 lg:row-span-2 lg:mt-0 xl:col-span-6">
            <BackgroundIllustration className="absolute left-1/2 top-4 h-[1026px] w-[1026px] -translate-x-1/3 stroke-gray-300/70 [mask-image:linear-gradient(to_bottom,white_20%,transparent_75%)] sm:top-16 sm:-translate-x-1/2 lg:-top-16 lg:ml-12 xl:-top-14 xl:ml-0" />
            <div className="-mx-4 h-[448px] px-9 [mask-image:linear-gradient(to_bottom,white_60%,transparent)] sm:mx-0 lg:absolute lg:-inset-x-10 lg:-bottom-20 lg:-top-10 lg:h-auto lg:px-0 lg:pt-10 xl:-bottom-12">
              <PhoneFrame className="mx-auto max-w-[366px]" priority>
                <AppDemo />
              </PhoneFrame>
            </div>
          </div>
          {/* <div className="relative mt-12 lg:col-span-7 lg:mt-0 xl:col-span-6">
            <div className="mx-auto mt-4 flex max-w-xl items-center justify-center gap-x-5 lg:mx-0 lg:justify-start">
              <MobileAppButtons source="hero" />
            </div>
          </div> */}
        </div>
      </Container>
    </div>
  )
}
