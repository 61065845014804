import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Head from 'next/head'
import { useRouter } from 'next/router'

import { CallToAction } from '@/components/CallToAction'
import { Faqs } from '@/components/Faqs'
import { Hero } from '@/components/Hero'
import { PricingSection } from '@/components/Pricing'
import { PrimaryFeatures } from '@/components/PrimaryFeatures'
import { SecondaryFeatures } from '@/components/SecondaryFeatures'
import { KeyFeaturesGridSection } from '@/features/app-features/components/sections/KeyFeaturesGridSection'
import { ContactUsSection } from '@/features/contact/components/sections/ContactUsSection'
import { LandingLayout } from '@/features/layout/components/LandingLayout'
import { Locale } from '@/types/locales'

const SITE_URL = process.env.NEXT_PUBLIC_FE_URL

const Home = () => {
  const { locale } = useRouter()
  const { t } = useTranslation('landing')

  return (
    <>
      <Head>
        <title>{t('landing.title')}</title>
        <meta name="description" content={t('landing.description')} />
        <meta property="og:title" content={t('landing.title')} />
        <meta
          property="og:image"
          content={`${SITE_URL}/assets/images/landing/og-image-${locale}.png`}
        />
      </Head>

      <LandingLayout>
        <Hero />
        <KeyFeaturesGridSection />
        <PrimaryFeatures />
        <SecondaryFeatures />
        <CallToAction />
        <PricingSection />
        <Faqs />
        <ContactUsSection />
      </LandingLayout>
    </>
  )
}

export default Home

export async function getStaticProps({ locale }: { locale: Locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, [
        'generic',
        'nav',
        'landing',
        'pricing',
        'features',
        'comparisons',
      ])),
      locale,
    },
  }
}
