import { VideoProps } from 'react-html-props'

import type { ImageProps } from 'next/image'

import { getS3FileUrl } from '@/utils/video'

export const VIDEOS: Record<string, VideoProps> = {
  CLEAR_CALENDAR_CROPPED: {
    src: getS3FileUrl('clear-calendar-cropped.webm'),
    poster: getS3FileUrl('clear-calendar-cropped-poster.png'),
    width: 2084,
    height: 720,
  },
  CLIENT_APPOINTMENT_HISTORY: {
    src: getS3FileUrl('client-appointment-history.webm'),
    poster: getS3FileUrl('client-appointment-history-poster.webp'),
    width: 1280,
    height: 720,
  },
  CLIENT_APPOINTMENT_HISTORY_CROPPED: {
    src: getS3FileUrl('client-appointment-history-cropped.webm'),
    poster: getS3FileUrl('client-appointment-history-poster.webp'),
    width: 720,
    height: 406,
  },
}

export const IMAGES: Record<string, ImageProps> = {
  SELF_BOOKING_LANDING: {
    src: getS3FileUrl('self-booking-landing.png'),
    alt: '',
    width: 3024,
    height: 1534,
  },
  SELF_BOOKING_LANDING_CROPPED: {
    src: getS3FileUrl('self-booking-landing-cropped.png'),
    alt: '',
    width: 2670,
    height: 1550,
  },
  EQUIPMENT: {
    src: getS3FileUrl('equipment.png'),
    alt: '',
    width: 1920,
    height: 812,
  },
}
