import { VideoProps } from 'react-html-props'
import { useInView } from 'react-intersection-observer'

import Image from 'next/image'

type LazyVideoProps = VideoProps

export const LazyVideo = ({ ...videoProps }: LazyVideoProps) => {
  const [ref, wasInView] = useInView({
    triggerOnce: true,
    threshold: 1,
  })

  const { poster, width, height } = videoProps

  return (
    <div ref={ref}>
      {wasInView ? (
        <video {...videoProps} />
      ) : (
        poster &&
        height &&
        width &&
        typeof height === 'number' &&
        typeof width === 'number' && (
          <Image src={poster} height={height} width={width} alt="" />
        )
      )}
    </div>
  )
}
