import { PhoneIcon } from '@heroicons/react/24/outline'

import { useTranslation } from 'next-i18next'
import Image from 'next/image'

import { Container } from '@/components/Container'
import { LazyVideo } from '@/components/LazyVideo'
import { IMAGES, VIDEOS } from '@/constants/s3'
import { KeyFeatureWithPreview } from '@/features/app-features/components/blocks/KeyFeatureWithPreview'

export const KeyFeaturesGridSection = () => {
  const { t } = useTranslation('landing')
  const { t: tf } = useTranslation('features')

  return (
    <Container>
      <section className="grid gap-4 pb-16" aria-describedby="keyFeatures">
        <h2
          className="pb-8 text-center text-3xl font-bold tracking-tight text-violet-950 md:text-left md:text-4xl"
          id="keyFeatures"
        >
          {t('landing.keyFeatures.title')}
        </h2>
        <KeyFeatureWithPreview
          title={t('landing.keyFeatures.calendar.title')}
          description={t('landing.keyFeatures.calendar.description')}
          label={t('landing.keyFeatures.calendar.label')}
          href="features/calendar"
        >
          <div className="ml-auto">
            <LazyVideo
              autoPlay
              loop
              muted
              playsInline
              controls={false}
              className="ml-auto rounded-t-xl  ring-1 ring-gray-400/10"
              {...VIDEOS.CLEAR_CALENDAR_CROPPED}
            />
          </div>
        </KeyFeatureWithPreview>
        <div className="grid gap-4 md:auto-rows-auto md:grid-cols-3 md:items-stretch">
          <div className="col-span-2">
            <KeyFeatureWithPreview
              title={t('landing.keyFeatures.sms.title')}
              description={t('landing.keyFeatures.sms.description')}
              label={t('landing.keyFeatures.sms.label')}
              href="features/messages"
            >
              <div
                aria-hidden
                className="ml-auto grid max-w-xl gap-2 p-4 transition-transform group-hover:-translate-y-4 group-hover:opacity-100 md:translate-y-16 md:pr-8 md:opacity-40"
              >
                <div className="flex items-center justify-between border-b border-gray-200 p-2  text-violet-950/70">
                  <p className="text-lg">
                    {t('landing.keyFeatures.sms.preview.senderName')}
                  </p>
                  <PhoneIcon className="h-5 w-5" />
                </div>
                <div className="grid h-fit max-h-fit gap-2 font-light group-hover:opacity-100 md:opacity-0">
                  <p className="py-2 text-center text-xs text-violet-500">
                    {t('landing.keyFeatures.sms.preview.day')}
                  </p>
                  <div className="rounded-lg bg-violet-50 p-4 text-sm text-violet-500 transition-shadow group-hover:shadow-sm">
                    <p>{t('landing.keyFeatures.sms.preview.message')}</p>
                  </div>
                  <p className="text-right text-xs text-violet-500">
                    {t('landing.keyFeatures.sms.preview.time')}
                  </p>
                </div>
              </div>
            </KeyFeatureWithPreview>
          </div>
          <KeyFeatureWithPreview
            label={t('landing.keyFeatures.equipment.label')}
            title={t('landing.keyFeatures.equipment.title')}
            description={tf('features.calendarAndVisits.equipment.paragraph')}
            href="features/management#equipment"
          >
            <Image {...IMAGES.EQUIPMENT} className="h-[18rem] object-cover" />
          </KeyFeatureWithPreview>
        </div>
        <div className="grid gap-4 md:grid-cols-3">
          <KeyFeatureWithPreview
            title={t('landing.keyFeatures.treatmentsTable.title')}
            label={t('landing.keyFeatures.equipment.label')}
            href="features/management#treatments"
          />
          <KeyFeatureWithPreview
            title={t('landing.keyFeatures.statistics.title')}
            label={t('landing.keyFeatures.equipment.label')}
            href="features/management#statistics"
          />
          <KeyFeatureWithPreview
            title={t('landing.keyFeatures.employees.title')}
            label={t('landing.keyFeatures.equipment.label')}
            href="features/management#employees"
          />
        </div>
        <div className="grid gap-4 md:grid-cols-2">
          <KeyFeatureWithPreview
            title={t('landing.keyFeatures.selfBooking.title')}
            description={t('landing.keyFeatures.selfBooking.description')}
            label={t('landing.keyFeatures.selfBooking.label')}
            href="features/self-booking"
          >
            <Image {...IMAGES.SELF_BOOKING_LANDING_CROPPED} />
          </KeyFeatureWithPreview>
          <KeyFeatureWithPreview
            title={t('landing.keyFeatures.clients.title')}
            description={t('landing.keyFeatures.clients.description')}
            label={t('landing.keyFeatures.clients.label')}
            href="features/clients"
          >
            <div className="ml-auto">
              <LazyVideo
                autoPlay
                loop
                muted
                playsInline
                controls={false}
                className="ml-auto rounded-t-xl  ring-1 ring-gray-400/10"
                {...VIDEOS.CLIENT_APPOINTMENT_HISTORY_CROPPED}
              />
            </div>
          </KeyFeatureWithPreview>
        </div>
      </section>
    </Container>
  )
}
